import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";

class JitsiMeet extends Component {
  domain = "meet.techarttrekkies.com.np";
  api = {};

  state = {
    room: null,
    userName: null,
    isAudioMuted: false,
    isVideoMuted: false,
  };

  componentDidMount() {
    this.setState(
      {
        room: this.props.getMeetingId,
        userName: this.props.getDispNme,
      },
      () => {
        if (window.JitsiMeetExternalAPI) {
          this.startMeet();
          this.studentAttendance();
        } else {
          alert("JitsiMeetExternalAPI not loaded");
        }
      }
    );
  }

  studentAttendance = () => {
    const param = {
      id: this.props.videoConferenceId,
    };
    axiosPost(URL.videoConfrenceStudentsAttendance, param, (response) => {
      if (response.status == 200) {
      }
    });
  };

  startMeet = () => {
    const options = {
      roomName: this.state.room,
      width: "100%",
      height: 500,
      configOverwrite: { prejoinPageEnabled: false },
      interfaceConfigOverwrite: {
        // overwrite interface properties
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          "fullscreen",
          "fodeviceselection",
          "hangup",
          "profile",
          "",
          "chat",
          "recording",
          "livestreaming",
          "etherpad",
          "sharedvideo",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          // "invite",
          "feedback",
          "stats",
          "shortcuts",
          "tileview",
          "videobackgroundblur",
          "download",
          "help",
          "mute-everyone",
          "e2ee",
          "whiteboard",
          "etherpad",
          // "security",
        ],
      },
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        displayName: this.state.userName,
      },
      whiteboard: {
        enabled: true,
        collabServerBaseUrl: "https://excalidraw.com/",
      },
    };

    this.api = new window.JitsiMeetExternalAPI(this.domain, options);

    setTimeout(() => {
      // why timeout: I got some trouble calling event listeners without setting a timeout :)

      // when local user is trying to enter in a locked room
      this.api.addEventListener("passwordRequired", () => {
        this.api.executeCommand("password", this.props.getPassword);
      });

      // when local user has joined the video conference
      this.api.addEventListener("videoConferenceJoined", (response) => {
        this.api.executeCommand("password", this.props.getPassword);
      });
    }, 10);

    this.api.addEventListeners({
      readyToClose: this.handleClose,
      participantLeft: this.handleParticipantLeft,
      participantJoined: this.handleParticipantJoined,
      videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
      audioMuteStatusChanged: this.handleMuteStatus,
      videoMuteStatusChanged: this.handleVideoStatus,
    });
  };

  handleClose = () => {};

  handleParticipantLeft = async (participant) => {
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async (participant) => {
    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant) => {
    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    return false;
    // return this.props.history.push("/thank-you");
  };

  handleMuteStatus = (audio) => {};

  handleVideoStatus = (video) => {};

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  render() {
    return <div id="jitsi-iframe"></div>;
  }
}

export default JitsiMeet;
